<template>
  <v-list nav>
    <template v-for="(item, i) in items">
      <menu-item :key="i" :item="item"/>
    </template>
    <v-list-item>
      <v-btn class="secondary" block @click="redirectLogout">{{ $t('logout') }}</v-btn>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import {
  mdiFormatListBulleted,
  mdiFolderText,
  mdiFormatListBulletedSquare,
  mdiHome,
  mdiCogOutline,
  mdiChartAreaspline,
  mdiGoogleAnalytics,
} from '@mdi/js';

export default {
  components: {
    MenuItem: () => import(/* webpackChunkName: "menu-items" */ './MenuItem.vue'),
  },
  data() {
    return {
      items: [
        {
          title: this.$t('homePage'),
          icon: mdiHome,
          children: null,
          to: { name: 'home' },
        },
        {
          title: this.$tc('order', 2),
          icon: mdiFormatListBulleted,
          children: null,
          to: { name: 'orders' },
        },
        {
          title: this.$t('support'),
          icon: mdiFolderText,
          children: [
            {
              title: this.$tc('patShort', 2),
              icon: mdiFormatListBulletedSquare,
              to: { name: 'pats' },
            },
            {
              title: this.$tc('intervention', 2),
              icon: mdiFormatListBulletedSquare,
              to: { name: 'interventions' },
            },
          ],
        },
        {
          title: this.$t('analysis'),
          icon: mdiGoogleAnalytics,
          children: [
            {
              title: this.$tc('intervention', 2),
              icon: mdiChartAreaspline,
              to: { name: 'interventionsChart' },
            },
          ],
        },
        {
          title: this.$tc('config', 2),
          icon: mdiCogOutline,
          children: null,
          to: { name: 'config' },
        },
      ],
    };
  },
  computed: {
    ...mapFields('auth', ['user']),
    ...mapGetters('auth', ['roles']),
  },
  methods: {
    ...mapActions('auth', ['logout']),
    async redirectLogout() {
      await this.logout();

      if (this.user === null) {
        this.$router.push({ name: 'login' });
      }
    },
  },
};
</script>
